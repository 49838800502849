<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <SearchBar
          @search="onSearch($event)"
          ref="searchBar"
          searchLabel="Search by file name..."
        />
      </v-col>
      <v-col align="right" v-if="!isWebAdminMode">
        <TrainingRecordLibraryFilter
          :filters="filters"
          :groups="trainingRecordGroups"
          :isSupervisor="isSupervisor"
          @applyFilters="applyFilters"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="exportData()"
              class="msaBlue white--text ml-6"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Training Records
            <v-badge
              :content="itemCount"
              :value="itemCount"
              bordered
              class="ml-2"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="headers"
            :items="trainingRecords"
            :options.sync="options"
            :server-items-length="itemCount"
            must-sort
            no-data-text="No results found"
          >
            <template v-slot:body="{ items }">
              <tr
                v-if="!trainingRecords.length"
                class="v-data-table__empty-wrapper"
              >
                <td
                  :align="$vuetify.breakpoint.xs ? 'start' : ''"
                  class="subtitle-2 font-weight-regular px-4"
                  colspan="6"
                  style="height: 48px"
                >
                  No results found
                </td>
              </tr>
              <v-slide-y-transition group tag="tbody">
                <template v-for="item in items">
                  <tr :key="item.id">
                    <td v-if="!isWebAdminMode && isSupervisor">
                      {{ item.user }}
                    </td>
                    <td>
                      <span
                        :style="{ cursor: 'pointer' }"
                        @click="downloadDocument(item)"
                        class="msaBlue--text"
                      >
                        {{ item.name }}
                      </span>
                    </td>
                    <td>
                      {{ item.groupName }}
                    </td>
                    <td>
                      {{ item.createdAt | filterAsLocalDate }}
                    </td>
                    <td>
                      {{ item.completedAt | filterAsLocalDate }}
                    </td>
                    <td>
                      {{ item.expireAt | filterAsLocalDate }}
                    </td>
                    <td v-if="isWebAdminMode">
                      <v-icon v-if="item.isPublished" color="green">
                        mdi-eye
                      </v-icon>
                      <v-icon v-if="!item.isPublished" color="orange">
                        mdi-eye-off
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-slide-y-transition>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import TrainingRecordLibraryFilter from '@/components/Filters/TrainingRecordLibraryFilter';
import { phantomAccessCheck } from '../global/functions.js';

export default {
  name: 'TrainingRecordsPage',
  components: {
    SearchBar,
    TrainingRecordLibraryFilter,
  },
  data() {
    return {
      filters: JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORD_LIBRARY_FILTERS),
      ),
      itemCount: 0,
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      trainingRecords: [],
    };
  },
  computed: {
    isWebAdminMode() {
      return this.$route.name == 'UserTrainingRecords';
    },
    isSupervisor() {
      return this.$store.getters.user.isSupervisor;
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Folder',
          value: 'groupName',
          class: 'lightGrey',
          width: '200px',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          class: 'lightGrey',
          width: '130px',
          align: 'center',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          width: '140px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Recertification Date',
          value: 'expireAt',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
      ];
      if (this.isWebAdminMode) {
        headers.push({
          text: '',
          value: 'isPublished',
          sortable: false,
          class: 'lightGrey',
          width: '50px',
          align: 'center',
        });
      } else {
        if (this.isSupervisor) {
          headers.unshift({
            text: 'User',
            value: 'user',
            class: 'lightGrey',
            width: '200px',
          });
        }
      }
      return headers;
    },
    trainingRecordGroups() {
      if (this.isWebAdminMode) {
        return [];
      }

      const groupMap = new Map();

      this.trainingRecords.forEach((item) => {
        const groupId = item.groupId;
        const groupName = item.groupName;

        if (!groupMap.has(groupId)) {
          groupMap.set(groupId, groupName);
        }
      });

      const result = Array.from(groupMap, ([id, name]) => ({ id, name }));
      return result;
    },
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.$route.name == 'TrainingRecords' &&
      !phantomAccessCheck(this, 'Training Records')
    ) {
      return;
    }
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.filename != '') {
        this.$refs.searchBar.setSearch(this.filters.filename);
      }
    });
  },
  methods: {
    getTrainingRecords() {
      this.loading = true;
      let url = '';
      const params = {
        options: this.options,
        loaderText: 'loading...',
      };
      if (this.isWebAdminMode) {
        params.userId = this.$route.params.id;
        params.search = this.filters.filename;
        url = 'get-user-training-records?format=json';
      } else {
        params.filters = this.filters;
        url = 'get-training-records-by-user?format=json';
      }
      this.$axios
        .post(url, params)
        .then((response) => {
          this.trainingRecords = response.data.trainingRecords;
          this.itemCount = response.data.count;
          if (this.isWebAdminMode) {
            this.$store.commit('updateSelectedUser', {
              ...response.data.user,
              status: response.data.user.status == 'Active' ? 1 : 0,
            });
          }
        })
        .catch((error) => error);
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.options = this.$helpers.deserializeOptions(query, 'createdAt', true);
      this.filters = this.$helpers.deserializeFilters(
        query,
        this.$constants.TRAINING_RECORD_LIBRARY_FILTERS,
      );

      this.getTrainingRecords();
    },
    updateUrl() {
      let params = this.$helpers.serializeOptions(
        this.options,
        'createdAt',
        true,
      );

      const filters = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.TRAINING_RECORD_LIBRARY_FILTERS,
      );

      params = { ...params, ...filters };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    onSearch(event) {
      this.filters.filename = event;
      this.options.page = 1;
      this.updateUrl();
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        isArchived: false,
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-training-record?format=json', params, options)
        .then((response) => {
          this.$helpers.previewFileInNewWindow(response.data);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$root.showMessage(
              'File not found',
              'File does not exist.',
              () => false,
              null,
              'OK',
            );
          } else {
            return error;
          }
        });
    },
    applyFilters(e) {
      this.filters = JSON.parse(JSON.stringify(e));
      this.options.page = 1;
      this.updateUrl();
    },
    exportData() {
      const params = {
        loaderText: 'Exporting data...',
        options: this.options,
        filters: this.filters,
      };
      const url = 'export-training-records-by-user?format=json';
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(
          response.data,
          'training-records-export.xlsx',
        );
      });
    },
  },
};
</script>
